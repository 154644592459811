<template>
  <div class="main-container">
    <div class="section-header"></div>
    <div class="section-form-content">
      <div class="container-report">
        <v-row>
          <v-col cols="12" md="6" lg="6" >
            <h3>
              <i class="fa fa-user-plus" aria-hidden="true"></i>
              {{ $t("Employee.import.import_data") }}
            </h3>

            <div v-if="qtyImport == 0 && importingEmployee.length == 0">
              <a @click="downloadTemplate"><i class="fas fa-cloud-download"></i> {{ $t("Employee.download_template") }}</a><br><br>
              <div class="choose-image">
                <div class="show-image">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <input @change="PreviewFile" type="file" class="input-file-image" ref="fileInput" />
                </div>
              </div>
              <span v-if="showFile" class="show-file-name">{{ $t("Employee.File_Name") }}:
                <span class="text-primary">{{ showFile }}</span></span><br />
              <v-btn color="blue-grey" class="ma-2 white--text lfont" @click="importData()">
                {{ $t("Employee.upload") }}
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <div v-if="qtyImport == 0 && importingEmployee.length == 0">

              <h3>
                <i class="fa fa-user-edit" aria-hidden="true"></i>
                {{ $t("Employee.import.resolve_data") }}
              </h3>
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <label class="label-input">{{ $t("Employee.dept") }}</label>
                  <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                    hide-selected v-model="departmentId">
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6" lg="6">
                  <label class="label-input">{{ $t("Employee.branch") }}</label>
                  <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="listBranch"
                    hide-selected v-model="branchId">
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" class="pb-6">
                  <v-radio-group v-model="resolve_options" column class="lfont">
                    <v-radio :label="$t('resolve_data.base_info')" color="primary" value="base_info"></v-radio>
                    <v-radio :label="$t('resolve_data.address')" color="primary" value="address"></v-radio>
                    <v-radio :label="$t('resolve_data.position')" color="primary" value="position"></v-radio>
                    <v-radio :label="$t('resolve_data.branch')" color="primary" value="branch"></v-radio>
                    <v-radio :label="$t('resolve_data.department')" color="primary" value="department"></v-radio>
                    <v-radio :label="$t('resolve_data.bank_account')" color="primary" value="bank_account"></v-radio>
                    <v-radio :label="$t('resolve_data.salary')" color="primary" value="salary"></v-radio>
                  </v-radio-group>
                  <a @click="downloadDataResolve"><i class="fas fa-cloud-download" style="margin-right: 5px;"></i>{{ $t("Employee.download") }}</a>
                </v-col>
                <v-col cols="12" md="6" lg="6">

                  <div class="choose-image">
                    <div class="show-image">
                      <i class="fas fa-cloud-upload-alt"></i>
                      <input @change="PreviewFileBaseInfo" type="file" class="input-file-image" ref="fileInput1" />
                    </div>
                  </div>
                  <span v-if="showFileBaseInfo" class="show-file-name">File Name:
                    <span class="text-primary">{{ showFileBaseInfo }}</span></span><br />
                  <v-btn color="blue-grey" class="ma-2 white--text" @click="importDataBaseInfo" v-if="showFileBaseInfo">
                    Upload
                    <v-icon right dark> mdi-cloud-upload </v-icon>
                  </v-btn>
                </v-col>
              </v-row>

            </div>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" lg="12">
            <div v-if="qtyImport > 0 || importingEmployee.length > 0">
              <h3 class="text-primary">
                ຈຳນວນຄົນໃນລາຍການ Import ({{ qtyImport > 0 ? qtyImport : importingEmployee.length }} ຄົນ)
              </h3>
              <v-btn color="blue-grey" class="ma-2 white--text" v-if="importingEmployee.length > 0"
                @click="importDataTotableEmp">
                Import
                <v-icon right dark> mdi-upload </v-icon>
              </v-btn>
              <v-btn color="error" class="ma-2 white--text" @click="clearImport">
                Clear
                <v-icon right dark> mdi-trash-can</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col cols="12" md="12" lg="12" v-if="importingEmployee.length > 0">
            <div class="card-report">
              <h4 class="text-danger">ລາຍການຂໍ້ມູນບໍຖືກຕ້ອງ</h4>
              <div>
                <NewImport :employees="importingEmployee" @success="changeData" v-if="purpose == 'new_import'" />

                <Salary :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'salary'" />

                <Address :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'address'" />

                <BankAccount :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'bank_account'" />

                <BaseInfo :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'base_info'" />

                <Branch :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'branch'" />

                <Department :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'department'" />

                <Position :employees="importingEmployee" @success="changeData" v-else-if="purpose == 'position'" />
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="4" lg="4" v-else>
            <p class="text-danger" v-for="(item, i) in server_errors" :key="i">
              {{ server_errors[`${i}`] }}
            </p>
            <div v-if="isSuccess">
              <v-alert dense text type="success"> Import success... </v-alert>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <loading v-if="isLoading" />
    <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchData">
    </Pagination>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";

import NewImport from "@/components/employee/import/table/new_import";
import Salary from "@/components/employee/import/table/salary";
import Address from "@/components/employee/import/table/address";
import BankAccount from "@/components/employee/import/table/bank_account";
import BaseInfo from "@/components/employee/import/table/base_info";
import Branch from "@/components/employee/import/table/branch";
import Department from "@/components/employee/import/table/department";
import Position from "@/components/employee/import/table/position";
export default {
  components: {
    btnLoading: false,
    Loading,
    Pagination,
    NewImport,
    Salary,
    Address,
    BankAccount,
    BaseInfo,
    Branch,
    Department,
    Position,
  },
  data() {
    return {
      purpose: "new_import",
      resolve_options: "base_info",
      editData: {
        scan_outside: "",
        finger_scan: "",
        emergency_contact: "",
        job_grade: "",
        branch: "",
        position: "",
        department: "",
        card_type: "",
        nationality: "",
        contact_type: "",
        district: "",
        bank_name: "",
        emp_number: "",
        coloumn: "",
      },
      isSuccess: false,
      server_errors: {},
      isLoading: false,
      workingBg: "rgb(0, 85, 255)",
      weeklyBg: "rgb(225, 175, 39)",
      offset: 10,
      pagination: {},
      per_page: 10,

      qtyImport: 0,
      importingEmployee: [],
      showFile: false,
      importFile: "",
      importFileBaseInfo: "",
      showFileBaseInfo: false,
      //
      listDepartment: [],
      listBranch: [],
      //
      filter: "",
      departmentId: "",
      branchId: "",
    };
  },
  methods: {
    downloadDataResolve() {
      this.isLoading = true;
      let item = {
        filter: this.filter,
        departmentId: this.departmentId,
        branchId: this.branchId,
        resolve_options: this.resolve_options
      };
      this.$axios
        .get(`company/export/employee/resolve`, { params: item })
        .then((res) => {
          this.$router
            .push({ name: "company.tracking-export-excel" })
            .catch(() => { });
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    importDataTotableEmp() {
      this.isLoading = true;
      this.$axios
        .post(`company/import/employee/upload`)
        .then((res) => {
          this.fetchImportingEmployee();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    clearImport() {
      this.isLoading = true;
      this.$axios
        .post(`company/import/employee/delete`)
        .then((res) => {
          this.fetchImportingEmployee();
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    editImportingEmpNumber(import_id, coloumn, emp_number) {
      this.editData.emp_number = emp_number;
      this.editImportingData(import_id, coloumn);
    },
    changeData(param) {
      this.editImportingData(param.id, param.editData);
    },
    editImportingData(import_id, editData) {
      this.isLoading = true;
      this.$axios
        .put(`company/import/employee/${import_id}`, editData)
        .then((res) => {
          this.fetchImportingEmployee();
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    downloadTemplate() {
      this.isLoading = true;
      this.$axios
        .get(`company/export/import/employee/template`, {
          responseType: "blob",
        })
        .then((res) => {
          this.isLoading = false;
          const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", "import-employee.xlsx");
          document.body.appendChild(fileLink);
          fileLink.click();
          document.body.removeChild(fileLink);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    clearFileInput() {
      this.showFile = '';
      this.showFileBaseInfo = '';
      this.$nextTick(() => {
        this.$refs.fileInput.value = '';
        this.$refs.fileInput1.value = '';
      });
    },
    importData() {
      let formData = new FormData();
      formData.append("file", this.importFile);
      formData.append("resolve_options", "new_import");
      this.isLoading = true;
      this.$axios
        .post(`company/import/employee`, formData)
        .then((res) => {
          this.isLoading = false;
          if (res.data.code === 200) {
            this.clearFileInput();
            this.server_errors = [];
            this.isSuccess = true;
            this.fetchImportingEmployee();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.clearFileInput();
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    importDataBaseInfo() {
      let formData = new FormData();
      formData.append("file", this.importFileBaseInfo);
      formData.append("resolve_options", this.resolve_options);
      this.isLoading = true;
      this.$axios
        .post(`company/import/employee`, formData)
        .then((res) => {
          if (res.data.code === 200) {
            this.clearFileInput();
            this.server_errors = [];
            this.isSuccess = true;
            this.fetchImportingEmployee();
          }
        })
        .catch((error) => {
          this.clearFileInput();
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        });
    },
    fetchImportingEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`company/import/employee`)
        .then((res) => {
          if (res) {
            this.purpose = res.data.data.purpose;
            this.importingEmployee = res.data.data.error_data;
            this.qtyImport = res.data.data.all_qty;
            this.isLoading = false;
            this.isSuccess = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    PreviewFile: function (event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be csv,xlsx"
        );
      } else if (input.files && input.files[0]) {
        this.importFile = input.files[0];
        this.showFile = this.importFile.name;
      }
    },
    PreviewFileBaseInfo: function (event) {
      const input = event.target;
      const file = input.files[0];
      const fileType = file["type"];
      const validImageTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (!validImageTypes.includes(fileType)) {
        this.$notification.ShowErrors(
          "top-right",
          "danger",
          3000,
          "image must be csv,xlsx"
        );
      } else if (input.files && input.files[0]) {
        this.importFileBaseInfo = input.files[0];
        this.showFileBaseInfo = this.importFileBaseInfo.name;
      }
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.fetchDepartment();
    this.fetchBranch();
    this.fetchImportingEmployee();
  },
};
</script>
<style scoped lang="scss">
.text-change {
  font-size: 12px !important;
  padding: 0px !important;
  width: 150px;
  color: red !important;
}

.main-container {
  margin: 10px !important;
  padding-bottom: 0px !important;
}

.section-action {
  height: auto;
  margin-top: 20px;
}

.section-form-content {
  margin-top: 25px !important;
  padding: 0px;
}

.highlight--schedule {
  .schedule--byEmployee {
    background-color: rgba(0, 168, 255, 0.15);
    color: #719aff;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }

  .schedule--byPosition {
    background-color: rgba(239, 87, 119, 0.2);
    color: #f82c80;
    font-weight: normal;
    border-radius: 40px;
    font-size: 12px;
    font-family: $font-family;
    letter-spacing: 1px;
    user-select: none;
  }
}

.container-report {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  padding: 10px;

  h1 {
    font-family: $font-family;
    font-size: 1.5rem;
    background-color: #ffffff;
    color: blue;
  }

  .card-report {
    width: 100%;
    height: auto;
    overflow: hidden;
    overflow-x: auto;
    //background-color: #FFFFFF;
    padding: 15px 0;
    font-family: $font-family;
    background-color: #ffffff;
    margin-bottom: 5px;
  }
}

.card-color-detail {
  margin-bottom: 25px !important;

  .color-detail {
    width: 100%;
    height: 80%;
    display: flex;
    border: 1px solid #eeeeee;
    align-items: center;
    border-radius: 16px;
    padding: 10px 10px;
    font-size: 14px !important;

    .circle-color {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: red;
    }

    p {
      font-family: $font-family;
      margin-bottom: 0;
      padding-left: 20px;
    }

    .standard {
      background-color: #ffffff;
      border: 1px solid #000000;
    }

    .is-leave {
      background-color: #80acf4;
    }

    .is-absence {
      background-color: red;
    }

    .is-skip {
      background-color: #fd9644;
    }

    .is-time-difference {
      background-color: #2f3542;
    }
  }
}

#mytable {
  font-family: $font-family;
  text-transform: capitalize;
  font-size: 12px !important;

  td {
    border: 1px solid;
  }

  .roster-chip {
    color: white;
    background: rgb(225, 175, 39) !important;
  }

  .emp-roster:hover {
    background: rgb(212, 212, 212);
  }

  .sum-work-sheet {
    color: blue;
    border: 1px solid #eae9e9;
    text-align: center;
    padding: 5px;
  }

  .ma-2 {
    font-size: 12px !important;
    margin: 2px !important;
  }
}

.td-part-name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 1px solid #000000;
}
</style>